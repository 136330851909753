.edit_mode {
    .video-container {
        margin: 0;
    }

    .video-title {
        background: linear-gradient(to bottom left, $blue, $green);

        h3 {
            font-size: rem(36);
            line-height: rem(42);
            font-weight: 500;
            color: white;
            text-transform: none;
        }
    }

    .user-group-title {
        background: linear-gradient(to bottom left, $yellow, $orange);

        h3 {
            font-size: rem(36);
            line-height: rem(42);
            font-weight: 500;
            color: white;
            text-transform: none;
        }
    }
}
