.blogger{
  &-hero{
    background-image: url("../images/bloggerHero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    .column {
    }
    &-content {
      transform: translateY(25%);
      // width: 50%;
      margin: 90px auto 0;

      h3 {
        color: #fff;
        font-size: 48px;
        font-family: $fontOne;
        text-transform: none;
        font-weight: 100;
      }
      p {
        color: #fff;
        font-family: $fontTwo;
        font-size: 20px;
        width: 90%;
        margin: 0 auto;

      }
    }
  }
  &-section {
    &-header {
      max-width: 900px;
      margin: 0 auto;
      padding: 50px;
      background-color: #fff;
      .textBlockElement h3 {
        font-weight: 400;
        text-transform: none;
        font-size: 36px;

      }

      &.top {
        margin: -100px auto 0;
      }
    }
    &-cards {
      max-width: 900px;
      margin: 0 auto;
      .heroPhotoElement {
        margin-top: 0;
        width: 70%;
        float: none;
        margin: 0 auto;
        img {
          min-width: 150px;
          min-height: 150px;
          border-radius: 50%;
        }
      }
      .contactElement {
        margin-bottom: 5px;
        margin-left: 7%;
        h3 {
          font-weight: 100;
          color: $dark1;
          letter-spacing: 1px;
          &.contact-element-first-name {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 100;
        }
      }
      .linkElement {
        margin-left: 7%;
        &::before {
          display: none;
        }
        &::after {
          content: "\f054";
          display: inline-block;
          position: absolute;
          color: $blue;
          font-family: fontAwesome;
          font-size: 14px;
          transform: translateY(0px);
          margin-left: 0px;
        }
        h4 a {
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          margin: 0;
          float: left;
          margin-right: 10px;
        }
      }
      .sn-social-media-list {
        margin: 0;
        margin-left: 7%;
        .sn-social-media-icon {
          // a {
            background-color: $blue;
          // }
        }
      }
    }
    &-tabs {
      .tabbedElement {
        margin: 0;
        .layoutContainerTabs {
          display: none;
          .column {
            padding: 0;
          }
        }
      }
    }
  }
}

// Tablet style
@include tablet {
  .blogger-section-cards {
    .col-md-3, {
      width: 24%;
    }
    .col-md-6 {
      width: 48%;
    }
  }
}


// Mobile Blogger Styles

html.has-mobile-nav {
  .blogger {
    &-hero {
      min-height: inherit;
      transform: translateY(-30px);
      &-content {
        transform: translateY(-20%);
        h3 {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    &-section {
      &-header {
        .textBlockElement {
          h3 { font-size: 30px; }
        }
        &.top {
          margin-top: -70px;
          padding: 20px;
        }

      }
      &-cards {
        &.heroPhotoElement {
          margin-top: 40px;
        }
      }
    }
  }
}
