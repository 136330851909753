$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: 320px;

#sn-site-logo {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -600px;
    width: 180px;
    height: $nav-height;
    padding: 0px;
    z-index: 1000;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: top 0.5s ease;
    @include desktop-flip {
        margin-left: -49vw;
        height: 65px;
    }
    @include mobile {
        width: 100%;
        margin-left: 0;
        left: 0;
        height: 60px;
        padding: 13px;
    }

    .up & {
        position: absolute;
    }

    .sn-site-logo-wrap {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }

    .sn-site-logo-background {
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        -webkit-background-size: contain;
        //@include mobile {
        //    background-image: url('../images/community-logo-mobile.svg') !important;
        //    background-position: left center;
        //}
    }

    #topNav.down & {
        top: -80px;
    }

    #topNav.up & {
        top: 0;
    }
}
