.pageEl.sponsored {
  .textBlockElement {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    float: none;
    .leftTextImage {
      max-width: 250px;
      min-height: 0 !important;
    }
    p {
      transform: translateY(-10px) translateX(10px);
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

html.has-mobile-nav {
  @media only screen and (max-width: 767px) {
    .pageEl.sponsored {
      padding: 20px;
      .textBlockElement {
        h3 { text-align: center !important; }
        p {
          transform: none;
          margin-top: 10px;
        }
        .leftTextImage {
          float: none;
          margin: 0 auto;
        }
      }
    }
  }
}
