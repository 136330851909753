// Global site footer styling

.global-site-footer, .large-menu {

  .se-description {
    h3 {
      font-family: $fontTwo;
      font-weight: 100;
      font-size: 20px;
      text-transform: none;
      color: $link-color;
    }
  }
  .se-connect {
    h4 {
      font-size: 13px;
      font-family: $fontTwo;
      color: $gray;
      // text-transform: none;
    }
  }
  // width: 120%;
  // margin: 50px auto 0;
  // margin: 0;
  // padding: 10%;
  background-color: $light;
  // margin-left: -10%;
  .column-2 {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-direction: column;
    max-height: 900px;

    .pageEl {
      flex-grow: 2;
      width: 28%;
      margin-left: 5%;
      margin-bottom: 0;

      h3 {
        font-size: 0.6em;
        color: $gray;
        font-weight: 100;
        a { color: $gray; }
      }
      p {
        margin-bottom: 0;
        a {
          color: $dark2;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.9;
        }
      }
    }
  }
}

// .global-site-footer {
//   padding-right: 0;
// }


.menu-container {
  max-width: inherit;
  width: 90%;
  margin: 20px auto;
}
.large-menu {
  .column-2 { max-height: 1000px; }
  // padding: 0;
  .se-description {
    h3  {
      text-transform: inherit;
      font-weight: 100;
      color: $link-color;
    }
  }
   h4 {
     font-size: 12px;
     color: $gray;
   }
   .menu-social {
     .sn-social-media-list {
       .sn-social-media-icon {
         border-radius: 10px;
         font-size: 1.5em;
       }
     }
   }
}

// Mobile Styling
html.has-mobile-nav {
  h4 { text-align: center; }
  .sn-social-media-list { text-align: center; }
}
