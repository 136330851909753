#siteContainer {
  h2#displayBodyHeader.header{
    color: white;
    background-color: $yellow;
    font-family: $fontTwo;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 12px;
    margin-bottom: -10px;
    margin-top: 20px;
    margin-left: 10px;
    border-bottom: none;
    transform: translateY(80%);
    &.orgs {
        background-color: $red;
    }

    &.coaches {
        background-color: $orange;
    }

    &.sports {
      background-color: $blue;
    }
  }
}


.page-nav {
    h1 {
        color: white;
        background-color: $yellow;
        font-family: $fontTwo;
        font-size: rem(24);
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline-block;
        // display: none;
        padding: 0 12px;
        margin-bottom: rem(18);
    }

    &.orgs h1 {
        background-color: $red;
    }

    &.coaches h1 {
        background-color: $orange;
    }

    &.sports h1 {
      background-color: $blue;
    }

    h2 {
        color: $dark2;
    }

    .dropdown-title {
        margin-bottom: 10px;
        text-transform: uppercase;
        cursor: pointer;
        letter-spacing: 1px;
        line-height: rem(24);

        &:after {
            content: '\f078';
            font-family: fontAwesome;
            font-size: 15px;
            padding-left: 10px;
        }
    }

    .textBlockElement {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        align-items: flex-start;
        -webkit-align-items: flex-start;
        @include tablet-small {
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
        }
        margin-top: 0;
    }

    .text {
        flex: 3.8;
        -webkit-flex: 3.8;
        @include tablet-small {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
        }
    }

    .page-nav-dropdown {
        flex: 1;
        -webkit-flex: 1;
        margin-top: 15px;
        position: relative;
        @include tablet-small {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
        }
    }

    #topics-nav {
        width: 100%;
        // overflow: scroll;
        background-color: white;
        position: absolute;
        border: 1px solid $light;
        box-shadow: $boxShadow;
        text-align: left;
        margin-left: -3px;
        padding: 20px;
        z-index: 20;
        list-style-type: none;
        @include tablet-small {
            width: 90%;
        }
        li a {
          font-size: 16px;
          line-height: 36px;
        }
        .topic-parent li { font-size: 16px;}
    }

    li {
        &.hidden {
            display: none;
        }

        &.visible {
            display: block;
        }

        a {
            font-size: rem(18);
            line-height: rem(36);
            color: $dark2;
            font-weight: 200;
        }
    }
}


// Article Pages

body.newsPage {
  .textBlockElement { margin-bottom: 50px; }
}



// Styling for the main featured article on a main page.
.main-upper {
  display: flex;
  display: -webkit-flex;
  .column-1 {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    .featured {
      flex: 1;
      -webkit-flex: 1;
      .newsSlideShow {
        height: 96%;
        .newsSlideShow-container {
          height: 100% !important;
          img {
            height: 85% !important;
            // left: -25% !important;
          }
        }
      }
    }
  }
}

// Topic dropdown styling

.topic-parent {
  a {
    font-size: 16px;
    color: $dark2;
    font-weight: 100;
  }
  li {
    list-style: none;
    text-indent: 18px;
    margin-bottom: 0;
    a {
      font-size: 16px;
      line-height: 36px;
      // font-size: 0.8em;
      // &::before {
      //   content: "- ";
      //   display: inline-block;
      //   position: relative;
      //   transform: translateX(-5px);
      // }
    }
  }
}

// view all aggregator article page styling

body:not(.edit_mode) {
  form#news_article { display: none; }
}

#newsArea {
  .paginationElement {
    background-image: none;
    background-color: transparent;
    padding: 6px 0px 18px;
    border-bottom: 2px solid #F3F3F4;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .paginationNav .current, .paginationNav em {
    font-style: normal;
    color: $gray;
  }
  .paginationNav {
    font-size: 16px;
    font-weight: 400;
  }
}

// mobile styling

html.has-mobile-nav {
  .interior-agg .newsAggregatorElement .condensed > a img {
    min-width: 160px;
  }
  .page-nav {
    h1 {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px;
    }
    .dropdown-title {
      text-align: right;
      margin-right: 15px;
    }
    .page-nav-dropdown {
      position: absolute;
      width: 100%;
      right: 0;
      top: -7px;
      #topics-nav {
        width: 100%;
        margin-left: 0;
        box-shadow: none;
      }
    }
  }
  .user-group-small {
    h3 { font-size: 14px; }
  }
  .main-upper {
    flex-wrap: wrap;
    .column-1 { width: 100%; }
  }
}

html.has-mobile-nav {
  @media only screen and (max-width: 760px) and ( max-height: 500px) {
    .interior-agg .newsAggregatorElement {
      .articleHasImage .newsItemHeader > a img {
        max-width: 170px;
      }
      .newsItemElement {
        .newsText p { margin-left: 190px; }
      }
    }
    .expanded {
      .newsItemHeader {
        h2 { margin-left: 190px; }
      }
    }
  }
}
