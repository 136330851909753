.menu-icon:not(.close-icon) {
    position: absolute;
    width: auto;
    right: 20px;
    top: 15px;
    cursor: pointer;
    z-index: 10;

    &:before {
        content: "\f0c9";
        color: $dark2;
        font-size: 30px;
        font-family: fontAwesome;

        &:hover {
            cursor: pointer;
        }
    }
    @include desktop-flip {
        top: 10px;
    }
}

.search-icon {
    position: absolute;
    width: auto;
    right: 70px;
    top: 15px;
    cursor: pointer;
    z-index: 200;

    &:before {
        content: "\f002";
        color: $dark2;
        font-size: 30px;
        font-family: fontAwesome;

        &:hover {
            cursor: pointer;
        }
    }
    @include desktop-flip {
        top: 10px;
    }
}

.menu-overlay {
    background-color: $light;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    opacity: 1;
    transform: scale(1);
    // transition: transform 100ms ease-out, opacity 300ms ease-in-out;
    transition: transform 500ms cubic-bezier(.31,-0.03,.47,1.18);
    // animation: menuAnimate 300ms ease-out;
    // animation-fill-mode: backwards;
    // animation-direction: alternate;
    overflow: scroll;

    &.hide {
        // transform: translate3d(-100%, 0, 0);
        transform: translateY(-110%);
        // transition: transform 300ms ease-out;
        // transform: scale(0);
        // opacity: 0;
        display: block !important;
        // animation: menuAnimate 300ms ease-out;
        // animation-fill-mode: backwards;
        // animation-direction: alternate;
        overflow: scroll;
        // transition: transform 300ms ease-out, opacity 200ms ease-in-out;
        // visibility: hidden;
    }

    &.visible {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}

.close-icon, .search-close {
    background-image: url('../images/close-icon.svg');
    background-size: contain;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.menu-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    max-width: 950px;
    padding: 15px;
    margin: 0 auto;
    @include desktop-flip {
        flex-wrap: wrap;
    }

    .community-logo {
        flex: 1;
        margin-right: 50px;
        @include desktop-flip {
            flex-basis: 100%;
            margin: 15px 0;
        }

        img {
            width: 100%;
        }
    }

    h5 a {
        font-size: rem(12);
        line-height: rem(18);
        color: $gray;
        letter-spacing: 1px;
        font-weight: 400;
        font-family: $fontTwo;
        text-transform: uppercase;
    }

    ul li {
        list-style: none;

        a {
            color: $dark2;
            font-size: rem(18);
            line-height: rem(36);
        }
    }

    .site-description {
        font-size: rem(24);
        line-height: rem(42);
        color: $blue;
        font-weight: 300;
        text-transform: none;
        margin: rem(18) 0;
    }

    .tagline {
        font-size: rem(12);
        line-height: rem(18);
        color: $gray;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: rem(18) 0;
    }

    .social {
        a:hover {
            text-decoration: none;
        }

        .facebook:before {
            content: '\f082';
            font-family: fontAwesome;
            font-size: 36px;
            color: #3b5998;
            padding: 0 5px 0 0;
        }

        .twitter:before {
            content: '\f081';
            font-family: fontAwesome;
            font-size: 36px;
            color: #4099FF;
            padding: 0 5px;
        }

        .google:before {
            content: '\f0d4';
            font-family: fontAwesome;
            font-size: 36px;
            color: #db3236;
            padding: 0 0 0 5px;
        }
    }
}

.main-nav {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include desktop-flip {
        flex-basis: 100%;
        margin: 15px 0;
    }

    .link-block {
        flex: 1 1 250px;
        max-width: 250px;
        margin-bottom: 30px;
        @include tablet-small {
            flex-basis: 100%;
            margin: 15px 0;
        }
    }

    .parents-athletes {
        order: 1;
    }

    .coaches {
        order: 2;
    }

    .sports-organizations {
        order: 3;
    }

    .user-group {
        order: 5;
    }

    .videos {
        order: 5;
    }

    .sportsengine-links {
        order: 6;
    }

    .home {
        display: none;
    }
}

// mobile styling

html.has-mobile-nav {
  .menu-container {
    width: 100%;
    margin-top: 0;
    background-color: $background-color;
  }

  .menu-overlay {
    background: transparent;
    .menu-container {
      height: 100vh;
      .heroPhotoElement { max-width: 275px; }
      .layout-33-66 { width: 100%; }
      .large-menu .column-2 .pageEl {
        width: 90%;
        .pageElement {
          margin-bottom: 0;
          &.open .text { height: auto; }
          &.open .mobile-drop::after {
            transform: rotate(180deg);
            transform-origin: center center;
          }
        }
        .mobile-drop {
          position: relative;
          float: right;
          display: inline-block;
          height: 30px;
          width: 30px;
          top: 0;
          right: -8px;
          &::after {
            content: "\f078";
            font-family: fontAwesome;
            display: inline-block;
            position: absolute;
            right: 0;
            font-size: 14px;
            transition: transform 200ms ease-out;
          }
        }
        h3 {
          letter-spacing: 0;
          line-height: inherit;
          font-size: 16px;
        }
        p a { font-size: 18px; }
      }
    }
    .se-description,
    .se-connect,
    .menu-social { display: none; }
    .close-icon {
      height: 20px;
      width: 20px;
      top: 25px;
      right: 15px;
      z-index: 10;
    }
    .column { padding: 0; }
    .heroPhotoElement {
      width: 90%;
      margin-top: 10px;
    }

    .column-2 {
      max-height: 100%;
      flex-wrap: nowrap;
      .pageEl {
        margin-bottom: 0;
        border-bottom: 1px solid $gray;

        .text {
          height: 0;
          overflow: hidden;
        }
      }
    }
  }
}

// Animations
@keyframes menuAnimate {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  // 75% {
  //   opacity: 0;
  // }
  95% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// Edit Mode adjustments

body.edit_mode {
  .large-menu,
  .global-site-footer {
    .column-2 .columnBounds {
      display: flex;
      flex-wrap: wrap;
      .addPageElementTop { width: 100%; }
      .drag {
        width: 30%;
        .pageEl {
          width: 100%;
          margin-left: 0;
          a { font-size: 13px; }
        }
      }
    }
  }
  .snFooterContainer { display: none; }
}
