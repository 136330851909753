/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontTwo;
    font-size: rem(14);
    font-weight: 600;
    a{
      background-color: $link-color;
      // box-shadow: 0 -3px $link-color-darken-10 inset;
      display: block;
      color: #fff;
      padding: 10px 20px 10px 10px;
      margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: background-color 200ms ease-out;
      &:hover{
        background-color: $link-color-darken-10;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: $link-color-darken-10;
      content: "\f0da"; // Right carrot
      // content: "\f0da"; // Right carrot
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: ""; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontTwo;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}

// Custom Community Link Styling

.linkElement {
  &.pageElement {
    margin-top: 0;
    margin-bottom: 0;
  }
  h4 a {
    background-color: transparent;
    color: $blue;
    font-weight: 100;
    font-size: 18px;
    margin-left: 10%;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    &:hover { background-color: transparent; }
  }
  .goToLink:after, .linkElement .emailLink:after { content: none; }
  &:before {
    content: "\f08e";
    font-family: fontAwesome;
    color: $blue;
    display: inline-block;
    position: absolute;
    font-size: 18px;
    margin-left: 10%;
    transform: translateX(-25px);
    height: 50px;
    width: 50px;
  }
  &::after {
    content: "";
    font-family: fontAwesome;
    color: $blue;
    display: inline-block;
    position: absolute;
    font-size: 18px;
    margin-left: 10%;
    transform: translateY(-65%) translateX(100%);
    height: 50px;
    width: 50px;
  }
}

.pageEl {
  &.facebook .linkElement.pageElement:before { content: "\f082"; }
  &.linkedin .linkElement.pageElement:before { content: "\f08c"; }
  &.email .linkElement.pageElement:before { content: "\f003"; }
  &.twitter .linkElement.pageElement:before { content: "\f099"; }
  &.instagram .linkElement.pageElement:before { content: "\f16d"; }
  &.youtube .linkElement.pageElement:before { content: "\f167"; }
  &.pinterest .linkElement.pageElement:before { content: "\f231"; }
  &.etsy .linkElement.pageElement:before { content: "\f2d7"; }
  &.external .linkElement.pageElement:before { content: "\f08e"; }
}

// mobile styling
// html.has-mobile-nav {
//   .linkElement.pageElement h4 {
//     text-align: initial;
//   }
// }
