// 5. Site Footer

.snFooterContainer {
    box-sizing: border-box;
    padding: 0;
    width: 100vw;
    // width: calc(100vw - 30px);
    margin-left: calc(-1*(100vw - 100%));
    padding: 30px calc((100vw - 100%)/2);
    background-color: $light;
    position: relative;
    bottom: 0;

    .edit_mode & {
        max-width: 100%;
        margin-left: 0;
    }
}

.pre-footer {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 70px;
    @include tablet-small {
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }

    .footer-links {
        flex: 2;
        -webkit-flex: 2;
        @include tablet-small {
            -webkit-flex-basis: 100%;
            flex-basis: 100%;
        }
    }

    .sportsengine-links {
        flex: 1;
        -webkit-flex: 1;
        margin: 15px 0;
        @include tablet-small {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
        }
    }

    .community-logo {
        flex: 1;
        -webkit-flex: 1;
        margin: 15px 20px;
        @include tablet-small {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
            margin: 15px 0;
        }

        img {
            width: 100%;
            max-width: 400px;
        }
    }

    .tagline {
        font-size: rem(12);
        line-height: rem(18);
        color: $gray;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: rem(18) 0;
    }

    .social {
        a:hover {
            text-decoration: none;
        }

        .facebook:before {
            content: '\f082';
            font-family: fontAwesome;
            font-size: 36px;
            color: #3b5998;
            padding: 0 5px 0 0;
        }

        .twitter:before {
            content: '\f081';
            font-family: fontAwesome;
            font-size: 36px;
            color: #4099FF;
            padding: 0 5px;
        }

        .google:before {
            content: '\f0d4';
            font-family: fontAwesome;
            font-size: 36px;
            color: #db3236;
            padding: 0 0 0 5px;
        }
    }

    h5 a {
        font-size: rem(12);
        line-height: rem(18);
        color: $gray;
        letter-spacing: 1px;
        font-weight: 400;
        font-family: $fontTwo;
        text-transform: uppercase;
    }

    ul li {
        list-style: none;

        a {
            color: $dark2;
            font-size: rem(18);
            line-height: rem(36);
        }
    }
}

.footer-links {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    @include tablet-small {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .link-block {
        flex: 1 1 250px;
        -webkit-flex: 1 1 250px;
        max-width: 250px;
        margin: 15px 0;
        @include mobile {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
        }
    }

    .home {
        display: none;
    }
}

#siteFooter {
    @extend %clearfix;
    display: block;
    margin: auto;
    padding: 10px 20px;
    position: relative;
    vertical-align: bottom;
    width: 100%;
    max-width: $maxWidth;
    font-size: 0.8em;
    clear: both;

    > ul {
        padding: 0;
        text-align: center;
        @include displayCenter($maxWidth);

        li {
            display: inline-block;
            text-align: center;
            padding: 0;
            font-size: rem(12);

            &:first-of-type {
                display: block;
                color: $dark2;
            }

            a {
                display: inline-block;
            }

            &:hover {
                // display: inline-block;
            }
        }
    }
}

#siteFooter li#poweredByNGIN {
    display: none;
    // a {
    //     display: block;
    //     width: 120px;
    //     height: 20px;
    //     position: relative;
    //
    //     &:after {
    //         content: url($asset-path-for+'/logo_images/logo_black.svg');
    //         display: block;
    //         position: absolute;
    //         top: 4px;
    //         left: 0;
    //         width: 100%;
    //     }
    //
    //     img {
    //         display: none;
    //     }
    // }
}
@media only screen and (max-width: 1024px) {
    #siteFooter {
        li {
            min-height: 0;
        }
    }

    #siteFooter li#poweredByNGIN {
        padding: 10px 0;
        width: 100%;
    }
}
@media only screen and (max-width: 640px) {
    #siteFooter {
        li {
            min-height: 0;
        }
    }

    #siteFooter li#poweredByNGIN {
        padding: 10px 0;
    }
}

// remove admin from site footer links
.footer-links {
  .link-block.admin {
    display: none;
  }
}
