/* News Article View */
// 1. News Article
// 2. Page layout padding/structure
// 3. Tags
// 4. Profile article styling

// 1. News Article
.newsItemElement {
    max-width: 960px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;

    &.pageElement {
        margin: 0 auto;
    }

    .titleText {
        font-family: $fontOne;
        font-size: rem(48);
        line-height: rem(54);
        margin-bottom: rem(15);
        font-weight: 200;
        color: $dark2;
        order: 1;
        @include tablet-small {
            font-size: rem(32);
            line-height: rem(38);
        }
    }

    .authorDetails {
        font-size: em(20);
        font-weight: 200;
        color: $dark1;
        margin-bottom: 15px;
        order: 2;
    }

    .newsDate,
    .teaserText {
        display: block;
        font-weight: 100;
        line-height: 1.5;
        margin-bottom: 1rem;
        color: $dark2;
        font-size: 16px;
    }
    .teaserText { display: none; }


    .previewText {
        order: 5;
        margin: 40px auto 30px;
        width: 100%;
        max-width: 760px;

        p {
            font-weight: 500;
            color: $dark1;
            font-size: rem(30);
            line-height: rem(42);
            @include tablet-small {
                font-size: rem(24);
                line-height: rem(36);
            }
        }
    }

    .imageOriginal {
        display: block;
        width: 100%;
        border: $borderWidth solid $borderColor;
        margin-bottom: 1rem;

    }

    .newsArticleThumbnail {
        margin: 0 auto;
        max-height: 465px;
        object-fit: cover;
        width: 100%;
        order: 3;
    }

    .sn-share-icons {
        order: 2;
        text-align: right;
        margin-bottom: 20px;
    }

    hr:first-of-type {
        display: none;
    }
}

.newsContentNode {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    .pageEl .block-quote,
    .pageEl .textBlockElement {
        // max-width: 660px;
        max-width: 80%;
        margin: 15px auto;
        float: none;
    }

    .pageEl .heroPhotoElement {
        width: 80%;
        margin: 15px auto;
        float: none;
    }

    .full-width .textBlockElement {
        max-width: none;
    }

}

// .newsContentNode:last-of-type {
//     max-width: $maxWidth;
// }

.author {
  &.pageEl {
    .textBlockElement { margin-left: 0; }
  }
    .textBlockElement {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
        padding: 15px 0;
        border-top: 2px solid $light;
        border-bottom: 2px solid $light;
        @include mobile {
            flex-wrap: wrap;
        }
    }

    .leftTextImage {
        flex: 1;
        -webkit-flex: 1;
        @include mobile {
            flex-basis: 100%;
        }
    }

    .text {
        flex: 2;
        -webkit-flex: 2;
        @include mobile {
            flex-basis: 100%;
            -webkit-flex-basis: 100%;
        }
    }

    img {
        border-radius: 50%;
    }

    &.org{
      img { border-radius: 0; }
    }

    h4 {
        text-transform: none;
        font-weight: 400;
        color: $dark2;
        margin-bottom: 5px;
    }

    p {
        font-size: rem(14);
        line-height: rem(24);

    }
}

.bottomOptions,
.newsTags {
    display: block;
}

// 2. Page layout padding/structure
.newsPage .yieldPageContent {
    padding-top: $bootstrap-padding-md * 2px !important;
}
@media only screen and (max-width: 768px) {
    .newsPage .yieldPageContent {
        padding-top: $bootstrap-padding-sm * 2px !important;
    }
}

// 3. Tags
.newsTags {
    font-size: 0.75rem;
}

// custom news article styling
body.newsPage:not(.edit_mode) {
  .layoutContainer {
    margin-top: 25px;
    margin-bottom: 50px;
  }


  // Stepped article styling
  .step {
    &-overview {
      h4 {
        text-transform: none;
        font-size: 20px;
        font-weight: 100;
        strong {
          font-weight: 400;
          margin-right: 5px;
          text-transform: uppercase;

        }
      }
      p {
        font-size: 16px;
        width: 50%;
      }
      .rightTextImage {
        width: 45%;
        margin-top: 10%;
        img { width: 100%; }
      }
    }
  }
  .step-one,
  .step-two,
  .step-three,
  .step-four,
  .step-five,
  .step-six,
  .step-seven,
  .step-eight,
  .step-nine,
  .step-ten {
    .heroPhotoElement {
      img { margin-top: 60px; }
    }
    .cutline {
      background-color: transparent;
      font-size: 36px;
      font-family: $fontTwo;
      font-weight: 500;
      color: $dark1;
      padding-left: 0;
      font-style: inherit;
      margin-top: 0;
      &::before {
        content: "Number 1";
        display: block;
        position: relative;
        font-size: 20px;
        font-weight: 500;
        font-family: $fontTwo;
        color: $dark2;
      }
    }
    &::before {
      content: "1";
      display: block;
      position: absolute;
      height: 100px;
      width: 100px;
      font-size: 90px;
      font-family: $fontTwo;
      font-weight: bold;
      color: #fff;
      background-color: $blue;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      transform: translate(110%, -50%);
      z-index: 10;
    }
  }

  .step-two::before { content: "2"; }
  .step-two .cutline::before { content: "Number 2"}
  .step-three::before { content: "3"; }
  .step-three .cutline::before { content: "Number 3"; }
  .step-four::before { content: "4"; }
  .step-four .cutline::before { content: "Number 4"; }
  .step-five::before { content: "5"; }
  .step-five .cutline::before { content: "Number 5"; }
  .step-six::before { content: "6"; }
  .step-six .cutline::before { content: "Number 6"; }
  .step-seven::before { content: "7"; }
  .step-seven .cutline::before { content: "Number 7"; }
  .step-eight::before { content: "8"; }
  .step-eight .cutline::before { content: "Number 8"; }
  .step-nine::before { content: "9"; }
  .step-nine .cutline::before { content: "Number 9"; }


  .split {
    .column {
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      margin-top: 20px;
      .pageEl {
        &:first-child {
          width: 100%;
          h3 { font-size: 32px; }
        }
        &:nth-child(2) {
          width: 45%;
        }
        &:nth-child(3) {
          width: 45%;
          .heroPhotoElement {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// 4. Profile Article Styling

.newsContentNode {
  .profile {
    &-hero {
      .heroPhotoElement { width: 100%; }
    }
    .facts {
      p strong {
        color: $dark1;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    h4, h3 {
      text-transform: none;
      font-size: 30px;
      line-height: 42px;
    }
    h3 {
      margin-bottom: 5px;
    }
    .textBlockElement { margin-left: 10%; }
    .cutline {
      background-color: transparent;
      padding-left: 5px;
    }
  }
}

// 5. ProTip article styling

.protip- {
  &step {
    &.one,
    &.two,
    &.three,
    &.four,
    &.five,
    &.six,
    &.seven,
    &.eight,
    &.nine,
    &.ten {
      &.pageEl .textBlockElement { max-width: 100%; }
      p {
        font-size: 16px;
        line-height: 24px;
      }
      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

      }
      &::before {
        content: "1";
        display: block;
        position: absolute;
        height: 60px;
        width: 60px;
        font-size: 30px;
        font-family: $fontTwo;
        font-weight: bold;
        color: #fff;
        background-color: $blue;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        transform: translate(-130%, 0);
        z-index: 10;
      }
    }
    &.two::before { content: "2"; }
    &.three::before { content: "3"; }
    &.four::before { content: "4"; }
    &.five::before { content: "5"; }
    &.six::before { content: "6"; }
    &.seven::before { content: "7"; }
    &.eight::before { content: "8"; }
    &.nine::before { content: "9"; }
    &.ten::before { content: "10"; }
  }
}
