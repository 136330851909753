/* Event Aggregators */
// 1. Global Styles
// 2. List Views
// 3. List Views - Media Queries
// 4. Five Day View
// 5. Five Day View - Media Queries

// 1. Global Styles
// .eventAggregatorElement {
//   .aggHeader {
//     @include special-header;
//   }
// }

// 2. List Views
.eventAggregatorElement {

    .vevent {
        position: relative;
        padding: 5px 0;

        &.even,
        &.odd {
            background-color: transparent;
        }
    }

    .details,
    .summary {
        list-style: none;
        padding-left: 95px;
    }

    .summary {
        font-family: $fontTwo;
        font-weight: 400;
        text-transform: none;
        font-size: rem(18);

        a {
            color: $dark1;
        }
    }

    .location,
    .time {
        display: block;
        font-size: rem(12);
        line-height: rem(24);
        color: white;
        text-transform: none;
        font-weight: 300;
    }

    .dtend,
    .dtstart {
        text-decoration: none;
    }

    .description,
    .extendedOptions,
    .ics_file_download,
    .tags {
        // display: none;
    }

    .goToLink {
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }

        &:before {
            display: none;
        }
    }

    .dateImage {
        display: block;
        list-style: none;
        background-color: $blue;
        padding: 0.5em;
        position: relative;
        float: left;
        width: 85px;
        height: 85px;
        box-sizing: border-box;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;

        .month {
            font-size: rem(14);
            line-height: rem(16);
            font-weight: 200;
            color: white;
        }

        .date {
            font-size: rem(36);
            line-height: rem(44);
            font-weight: 400;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .formElement {
        padding: 10px 0; // This should be better defined in global form styles (or removed from there to be styled per element)

        [id$="_end_date_day"],
        [id$="_start_date_day"] {
            width: 8em;
        }
    }

    .calendar-list & {

        margin-left: 42px;
        width: 90%;

      .aggHeader {
        display: none;
      }

      .vevent {
        &.even,
        &.odd {
          padding-top: 30px;
        }
      }

      .dateImage {

        width: 120px;
        height: 120px;

        .month {
          font-size: 20px;
          margin-top: 12px;
        }
        .date {
          font-size: 40px;
        }
      }

      .summary {
        font-size: 32px;
        line-height: 42px;
        font-weight: 500;
        font-family: $fontTwo;
        padding-left: 150px;
      }

      .details {
        padding: 10px 150px 20px;
        border-bottom: $borderWidth solid $borderColor;
      }

      .location {
        font-size: 20px
      }

      .time {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #68767f;
        padding-bottom: 16px;
      }

      .tags {
        display: none;
      }

    }

  }

.noEntry {
    font-weight: 300;
    font-size: rem(16);
    text-align: left;
    background-color: transparent;
}

// 3. List Views - Media Queries
@include page-element-break(330) {
    .eventAggregatorElement {
        .dateImage {
            float: none;
            margin-bottom: 1em;

            &:after {
                bottom: 0;
                left: 50%;
                top: initial;
                right: initial;
                transform: translate(-50%, 45%) rotate(45deg);
            }
        }

        .details,
        .summary {
            padding-left: 0;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout-25-25-50,
    .layout-50-25-25 {
        .col-md-3,
        .col-md-6 {
            .eventAggregatorElement {
                .dateImage {
                    position: absolute;
                    margin-bottom: 0;

                    &:after {
                        top: 50%;
                        right: 0;
                        bottom: initial;
                        left: initial;
                        transform: translate(45%, -50%) rotate(45deg);
                    }
                }

                .details,
                .summary {
                    padding-left: 95px;
                }
            }
        }
    }

    .layout-25-50-25 {
        .col-md-3,
        .col-md-6 {
            .eventAggregatorElement {
                .dateImage {
                    position: relative;
                    margin-bottom: 1em;

                    &:after {
                        bottom: 0;
                        left: 50%;
                        top: initial;
                        right: initial;
                        transform: translate(-50%, 45%) rotate(45deg);
                    }
                }

                .details,
                .summary {
                    padding-left: 0;
                }
            }
        }
    }
}

// 4. Five Day View
.eventPreview {
    text-align: left;
    width: 100%;
    table-layout: fixed;

    th {
        @include table-th;
        @include table-th-text;
        word-break: break-word;

        a {
            color: #FFF;
            font-weight: 600;
        }
    }

    td {
        font-size: 12px;
        padding: 10px;
        background: #eee;
        vertical-align: top;
        word-break: break-word;

        &:before {
            content: attr(data-week-view-date)'';
            display: none;
            width: 100%;
            position: relative;
            box-sizing: border-box;
            padding: 7px 12px;
            border-bottom: $table-cell-spacing solid #FFF;
            background: $link_color;
            color: #fff;
            text-transform: uppercase;
            font-family: $fontOne;
            font-size: 1em;
            line-height: 1.1;
            font-weight: 600;
        }
    }

    td,
    th {
        border: $table-cell-spacing solid #FFF;

        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    .event + .event {
        padding-top: 10px;
    }

    .noentry {
        word-break: normal;
    }
}

// 5. Five Day View - Media Queries
@include page-element-break(430) {
    .eventPreview {
        border-top: $table-cell-spacing solid #FFF;

        &,
        tbody,
        td,
        tr {
            display: block;
        }

        thead {
            display: none;
        }

        td {
            border: 0;
            padding: 0;
            margin-bottom: $table-cell-spacing;
            word-break: word-break;

            &:before {
                display: block;
            }
        }

        .event {
            padding: 10px;

            & + .event {
                padding-top: 0;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout-25-25-50,
    .layout-50-25-25 {
        .col-md-6 {
            .eventPreview {
                display: table;
                border-top: 0;

                thead {
                    display: table-header-group;
                }

                tbody {
                    display: table-row-group;
                }

                tr {
                    display: table-row;
                }

                td,
                th {
                    display: table-cell;
                }

                td {
                    padding: 10px;
                    margin-bottom: none;
                    word-break: word-break;

                    &:before {
                        display: none;
                    }
                }

                .event {
                    padding: 0;
                }
            }
        }
    }

    .layout-25-50-25 {
        .col-md-6 {
            .eventPreview {
                border-top: $table-cell-spacing solid #FFF;

                &,
                tbody,
                td,
                tr {
                    display: block;
                }

                thead {
                    display: none;
                }

                td {
                    border: 0;
                    padding: 0;
                    margin-bottom: $table-cell-spacing;
                    word-break: word-break;

                    &:before {
                        display: block;
                    }
                }

                .event {
                    padding: 10px;

                    & + .event {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 891px) and (max-width: 999px) {
    .layout-25-25-50,
    .layout-50-25-25 {
        .col-md-3 {
            .eventPreview {
                display: table;
                border-top: 0;

                thead {
                    display: table-header-group;
                }

                tbody {
                    display: table-row-group;
                }

                tr {
                    display: table-row;
                }

                td,
                th {
                    display: table-cell;
                }

                td {
                    padding: 10px;
                    margin-bottom: none;
                    word-break: word-break;
                    border: $table-cell-spacing solid #FFF;

                    &:first-child {
                        border-left: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }

                    &:before {
                        display: none;
                    }
                }

                .event {
                    padding: 0;
                }
            }
        }
    }
}

// custom styles

#event_show {
  max-width: 1000px;
  // margin: 0 auto;

  .summary {
    font-size: 26px;
    margin-left: 10px;
  }
  .details {
    margin-left: 10px;
  }
  .time {
    color: $dark2;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .location { margin-bottom: 5px; }

}

.eventContentNode {
  max-width: 1000px;
}

@include mobile {
  .calendar-list {
    .pageElement.eventAggregatorElement {
      margin-left: inherit;
      width: 95%;

      .summary {
        font-size: 24px;
        line-height: 32px;
      }
      .details {
        padding: 0;
        padding-top: 10px;
        clear: both;
        .time {
          font-size: 18px;
          font-weight: 100;
        }
        .description {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}
