@include tablet {

  #siteContainer { padding-top: 0; }

  .menu-overlay {
    .menu-container {
      width: 100%;
      margin: 0 auto;
      .large-menu {
        width: 100%;
        .column-2 {
          width: 100%;
          .pageEl {
            width: 33% !important;
            margin-left: 3%;
          }
        }
      }
    }
  }

  #GoogleContentSearch .theme-search-modal .theme-search-title { width: 60%; }
  #GoogleContentSearch .theme-search-modal .theme-search-box { width: 60%; }


  .main-upper {
    .column-2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .featured {
        // margin-right: 10px;
        width: 49%;
        .newsAggregatorElement {
          min-height: 350px;
          float: none;
        }
      }
    }
  }

  .newsSlideShow-article {
    .newsSlideShow-headline {
      h1 a { font-size: 32px !important; }
      p {
        font-size: 18px;
        font-style: normal;
      }
    }
  }

  .featured {
    .newsAggregatorElement {
      min-height: 350px;
      h4 a {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .full-width.video-container .column {
    width: 80%;
    float: none;
  }

  .global-site-footer .column {
    width: 100%;
    .textBlockElement { text-align: center; }
    .menu-social { text-align: center; }
  }
  footer.snFooterContainer { margin-left: inherit; }

  .title-only .newsAggregatorElement .condensed:first-of-type a > img { min-height: 170px; }
}
