// custom styling for video section being brought in from the assets page.

.video-assets.video-container {
  padding: 30px 100px;
  .column {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .pageEl:not(.video) {
      width: 100%;
    }
    .video {
      width: 30%;
    }
  }
}
