// Webmaster of the week profile styling

.wmotw {
  .column {
    margin-top: 50px;
  }
}

.commentThreadElement-container {
  h3 {
    background: transparent;
    font-size: 32px;
    line-height: 32px;
    color: $dark1;
    text-transform: none;
    padding: 0;
  }

  .comment-form {
    textarea {
      border-radius: 4px;
      min-height: 10em;
      width: 80%;
      border: 1px solid $gray;
      box-shadow: 0 3px 4px 0 rgba(104, 118, 127, 0.3);
    }
    fieldset {
      display: inherit;
      img {
        height: 50px;
        width: 50px;
        margin-bottom: 25px;
      }
      select {
        margin-bottom: 25px;
      }
      .submit {
        border-radius: 4px !important;
        background-color: $green !important;
        border: none;
        width: 200px;
        margin-bottom: 10px !important;
      }
    }
  }
}


// Showcase specific Styling

.newsContentNode {
  .showcase {
  &-feature {
      .heroPhotoElement { width: 100%; }
      .pageElement.textBlockElement {
        margin: 0;
        h3 {
          color: $dark2;
          margin-bottom: 0;
        }
      }
      .cutline {
        background-color: transparent;
        padding-left: 5px;
      }
    }
  }
}

// pull quote

.pageEl.pull-quote {
  .pageElement.textBlockElement {
    // width: 65%;
    blockquote {
      text-transform: none;
      font-family: $fontOne;
      // color: $blue;
      font-size: 30px;
      font-weight: 100;
      line-height: 36px;
      margin-top: 10px;
      p {
        font-size: 30px;
        color: $blue;
      }
      &:before {
        content: "\“";
        position: absolute;
        display: block;
        font-size: 70px;
        color: $gray;
        transform: translateX(-120%);
      }
      &:after { display: none; }
    }
    p {
      text-align: right;
      font-size: 14px;
      color: $dark1;
      margin-top: 20px;
    }
  }
}

h5.articleType {
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-family: $fontTwo;
  display: block;
  padding: 5px;
  background-color: $red;
  width: 150px;
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.newsTags {
  max-width: 900px;
  margin: 0 auto;
  border-bottom: none;
}
.bottomOptions { display: none; }

// general mobile styling

html.has-mobile-nav {
  .newsItemElement {
    .authorDetails {
      font-size: 14px;
      font-weight: 100;
      .newsDate { display: none; }
    }
    .sn-share-icons { text-align: left; }
    .previewText {
      margin: 0;
      h4 {
        text-transform: none;
        font-size: 16px;
        font-weight: 100;
      }
    }
  }
  .newsContentNode {
    .layoutContainer {
      margin: 0;
      .column {
        padding: 0;
        margin-top: 0;
        .heroPhotoElement {
          width: 100%;
        }
        .textBlockElement {
          h2 {
            font-size: 22px;
            line-height: 30px;
          }
          .rightTextImage { float: none; }
        }
        .commentThreadElement {
          .commentThreadElement-container {
            width: 90%;
            margin: 0 auto;
          }
        }
        .author {
          .textBlockElement { margin-left: auto; }
        }
        .related-agg {
          width: 90%;
          margin: 0 auto;
        }
        .pageOptions li {
          font-size: 16px;
        }
      }
    }
  }
}
