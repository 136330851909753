/* News Aggregator */
// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More
// 6. Custom styling

// 1. Mixins (Remove when liquid)
@mixin newsagg-header {
    font-size: em(16);
    font-weight: 700;
    text-transform: none;
    line-height: 1.35;
}
@mixin newsagg-image {
    display: inline-block;
    padding: 3px 15px 7px 0;

    img {
        width: 100%;
        max-width: 270px;
        max-height: 170px;
        object-fit: cover;
        display: block;
        height: 100% !important;
    }
}
@mixin newsagg-date-author {
    color: #666;
    font-size: em(12);
    line-height: 1.25;
    display: inline-block;
    margin-right: 0.5em;
}
@mixin newsagg-teaser {
    font-family: $fontTwo;
    text-transform: none;
    font-size: rem(14);
    line-height: rem(24);
    color: $dark1;
    margin: 0.75em 0;
}

// 2. Global styles
.newsAggregatorElement {
    // .item {
    //     @include list-item();
    // }

    .dateAuthor {
        line-height: 1;
    }

    .newsAuthor,
    .newsDate {
        @include newsagg-date-author;
    }

    h2 a {
        font-size: rem(24);
        font-weight: 200;
        line-height: rem(30);
        color: $dark2;
    }

    h4 {
        text-transform: none;
        font-size: rem(14);
        line-height: rem(24);
    }

    h4 a {
      font-size: rem(18);
      font-weight: 200;
      line-height: rem(24);
      color: $dark2;
    }

    .articleHasImage {
        min-height: 170px;

        .commentsAndMore,
        .newsItemHeader,
        .newsText,
        > h4 {
            // padding-left: 290px;
            padding-left: 35%;
        }

        .newsItemHeader {
            position: relative;

            > a {
                position: absolute;
                top: -2px;
                left: 0;
            }
        }
    }
}

.newsAggregatorElement .expanded {
    .dateAuthor {
        display: none;
    }
}

//
.aggHeader {
    font-size: rem(18);
}

// 3. Expanded Previews
.expanded {
    .newsItemHeader > a {
        @include newsagg-image;
    }

    .newsItemElement {
        h4 {
            @include newsagg-teaser;
        }
    }

    .newsItemHeader {
        h2 {
            @include newsagg-header;
        }
    }

    .newsText {
        clear: both;
        margin-top: 1em;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-transform: none;
            font-weight: 600;
            font-size: 1em;
            letter-spacing: normal;
            margin: 0 0 0.5em;
            padding: 0;
        }

        ul {
            list-style-type: disc;
        }
    }
}

// 4. Condensed Previews
.condensed {
  min-height: 150px;
    li {
        line-height: 1;
    }

    > a {
        .newsAggregatorElement & {
            @include newsagg-image;
            // float: left;
            float: none;
            max-width: 180px;
            display: block;
            // margin: 0 auto;
        }
    }

    .teaser {
        @include newsagg-teaser;
    }

     > ul,
    .details {
        list-style-type: none;

        h4 {
            @include newsagg-header;
            text-align: left;
        }
    }

    .details {
        padding-left: 90px;
    }
}

// 5. Read more and comments
.commentCount {
    .button-commentCount {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        line-height: 1;

        &:hover {
            background: none;
        }

        .button-image-right {
            display: none;
        }

        .button-content {
            margin: 0;
            padding: 0;
            position: relative;
            border: none;
            background: none;
            color: $link-color;
            font-weight: 600;
            top: 2px;
            transition: all $transition-standard;

            &:hover {
                background: none;
                border: none;
                color: $hover-color;
            }

            &:before {
                content: "";
                display: inline-block;
                position: relative;
                font-family: FontAwesome;
                line-height: 1;
                content: "\f0e5";
                top: -1px;
                right: 0;
                padding-right: 0.25em;
            }
        }
    }
}

.commentsAndMore {
    display: none;
    // line-height: 1;
    // margin: 5px 0 0;
    //
    // .readMore {
    //     display: inline-block;
    //     margin-right: 0.5em;
    //
    //     a {
    //         margin: 0;
    //     }
    // }
}

// 7. Responsive
@include page-element-break(400) {
    .newsAggregatorElement {
        .articleHasImage {
            .commentsAndMore,
            .newsItemHeader,
            .newsText,
            > h4 {
                padding-left: 0;
            }

            .newsItemHeader {
                > a {
                    position: relative;
                    top: initial;
                    left: initial;
                }
            }
        }

        .item > a {
            position: relative;
            top: initial;
            left: initial;
        }

        .condensed .details {
            padding-left: 0;
        }
    }
}
@include page-element-break(300) {
    .newsAggregatorElement {
        .articleHasImage {
            .commentsAndMore,
            .newsItemHeader,
            .newsText,
            > h4 {
                padding-left: 0;
            }

            .newsItemHeader {
                > a {
                    position: relative;
                    float: none;
                }
            }
        }

        .item > a {
            position: relative;
            float: none;
        }

        .condensed .details {
            padding-left: 0;
        }
    }
}

// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px) {
    .layout-25-25-50,
    .layout-50-25-25 {
        .col-md-3,
        .col-md-6 {
            .newsAggregatorElement {
                .articleHasImage {
                    .commentsAndMore,
                    .newsItemHeader,
                    .newsText,
                    > h4 {
                        padding-left: 90px;
                    }

                    .newsItemHeader {
                        > a {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }
                }

                .condensed > a {
                    display: inline-block;
                    float: left;
                    padding: 7px 15px 7px 0;
                }

                .condensed .details {
                    padding-left: 90px;
                }
            }
        }
    }

    .layout-25-50-25 {
        .col-md-3,
        .col-md-6 {
            .newsAggregatorElement {
                .articleHasImage {
                    .commentsAndMore,
                    .newsItemHeader,
                    .newsText,
                    > h4 {
                        padding-left: 0;
                    }

                    .newsItemHeader {
                        > a {
                            position: relative;
                            display: block;
                            float: none;
                        }
                    }
                }

                .condensed > a {
                    display: block;
                    float: none;
                }

                .condensed .details {
                    padding-left: 0;
                }
            }
        }
    }
}

// 6. Custom Styling for Community site


.featured-latest {
  .newsAggregatorElement {
    padding-right: 5%;
    .condensed > a { float: left; }
  }
  .aggHeader {
    font-size: 30px;
    color: $dark2;
    margin-bottom: 30px;
    font-weight: 300;
    text-transform: capitalize;
  }
  .item.condensed {
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 4px;
    a img { object-fit: contain; }
  }
  .details {
    padding-top: 0.6em;
    h4 a {
      font-size: 1.3em;
      font-weight: 100;
      color: $dark2;
    }
    .dateAuthor { display: none; }
  }

  h2 {
    margin-bottom: 0;
    a {
      line-height: 1.3em;
      font-weight: 100;
    }
  }
  h4 {
    margin: 0;
    color: $dark2;
  }

  .extendedOptions {
    border-bottom: none;
    text-align: left !important;
    // .rssLink {
    //   display: none;
    // }
    .goToLink {
      display: inline-block;
      &:before {
        content: "\f078";
        left: 0;
        transform: translateY(-2px);
      }
      &:after {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        display: inline-block;
        background: #fff;

      }
    }
  }
}

// interior news aggregator styling

.interior-agg {
  .newsAggregatorElement {
    .articleHasImage .newsItemHeader { padding-left: 190px; }
    .expanded .newsItemHeader > a img { max-width: 170px; }
    .condensed > a img {
      max-width: 170px;
      min-width: 170px;
    }

    // .newsItemHeader h2 { margin-top: 20px; }
    .newsItemElement h4 { display: none; }
    .item {
      margin-top: 15px;
      border-bottom: 1px solid $gray;
      padding-bottom: 15px;

      &.extendedOptions {
        border-bottom: none;
        text-align: left !important;
        .goToLink {
          padding-left: 30px;
          &:before {
            content: "\f078";
          }
          &:after {
            content: "";
            position: absolute;
            display: inline-block;
            height: 20px;
            width: 20px;
            background-color: #fff;
          }
        }

        // .rssLink { display: none; }
      }

      // condendsed styling for 100 article view.
      &.condensed {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        .details {
          padding-left: 0;
        }
      }
    }
    .newsItemElement {
      // h4 { display: none; }
      .newsText {
        padding-left: 190px;
        margin-top: 0;
        strong, h4 {
          font-weight: 100;
          font-size: 14px;
        }
      }
      .newsPreviewText { margin-bottom: 0; }

      .newsText p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.layoutContainer {
  .recommended-reads {
    h3 {
      color: $dark2;
    }
    @include mobile { margin-top: 40px; }
    margin-bottom: 20px;
    margin-top: 20px;
    &::before{
      content: "";
      border-top: 6px solid $link-color;
      display: block;
      text-transform: uppercase;
      color: $dark2;
      padding-top: 10px;
    }
    .item {
      img { display: none; }
        border-top: 1px solid $gray;
        min-height: initial;
    }
    .details {
      .dateAuthor { display: none; }
      .teaser { display: none; }
    }
    .extendedOptions { display: none; }
  }
}

//exception for the site guide page
body#site-guide {
  .interior-agg .condensed {
    min-height: inherit;
  }
}

// Cusotm Mobile Styling

html.has-mobile-nav {
  .featured.main {
    .newsAggregatorElement {
      .condensed h4 { padding-top: 0; }
      .condensed h4 a {
        font-size: 20px;
        line-height: 22px;
      }
      .item .teaser{
        font-size: 14px;
        line-height: 17px;
        margin-top: 0;
        padding-right: 10px;
      }
    }
  }
  @media only screen and (max-width: 767px ){
    .interior-agg {
      .newsAggregatorElement {
        .articleHasImage {
          .newsItemHeader { padding-left: 0; }
          .newsItemHeader > a img { max-width: inherit; }
          .newsText { padding-left: 0; }
        }
      }
    }
  }
}
