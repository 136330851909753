// --------------------------------
// FEATURED LARGE
// --------------------------------

.featured {
  .commentsAndMore,
  .dateAuthor,
  .extendedOptions,
  .teaser {
    display: none;
  }

  &-home {
    display: flex;
    display: -webkit-flex;

    .column-2 {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: space-between;
      -webkit-justify-content: space-between;
    }
  }

  .aggHeader {
    @include special-header;
    position: absolute;
    bottom: 20px;
    left: 25px;
  }

  &.admins {
    .aggHeader {
      background-color: $red;
    }
  }

  &.sports-page {
    .aggHeader {
      background-color: $blue;
    }
  }

  .newsAggregatorElement .condensed {
    &.item {
      box-shadow: $boxShadow;
      padding: 0 0 5px;
      transition: 0.3s ease;
      min-height: 375px;

      &:hover {
        box-shadow: 0 3px 6px 2px rgba(104,118,127,.3);
      }
    }

    h4 {
      font-size: rem(24);
      line-height: rem(30);
      font-family: $fontTwo;
      display: inline-block;
      padding: 15px 15px 0;
      @include tablet-small {
        font-size: rem(18);
        line-height: rem(24);
        text-align: left;
      }

      a {
        color: $dark2;
        font-size: 21px;
        font-weight: 300;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .details {
      padding-left: 0;
    }

    a {
      width: 100%;
      position: relative;
      padding: 0;
      max-width: 100%;
      @media only screen and (min-width: 1254px) {
        min-height: 250px;
      }

    }

    img {
      width: 100%;
      // height: auto !important;
      max-width: 100%;
      min-height: 250px;
      object-fit: cover; //no support in IE
      padding: 0;
      margin: 0;
      @include tablet-small {
        max-height: 400px;
      }
    }
  }
}

.featured {
  .newsSlideShow {
    &-headline {
      h1 {
        padding-bottom: 10px;
      }

      p {
        font-weight: 100;
        font-size: 20px;
        font-style: normal;
        margin-bottom: 10px;
      }
    }

    box-shadow: $boxShadow;
    // padding: 0 0 22px;
    padding: 0;
    transition: 0.3s ease;

    &:hover {
      box-shadow: 0 3px 6px 2px rgba(104,118,127,.3);
    }

    .newsSlideShow-container {
      padding-bottom: 0;
      margin-bottom: 0;

      img {
        left: 0 !important;
      }
    }

    h4 {
      font-size: 36px;
      line-height: 42px;
      padding: 25px 30px 0;
      @include tablet-small {
        font-size: rem(24);
        line-height: rem(30);
      }

      a {
        color: $dark2;
        font-weight: 300;
      }
    }
  }

  .newsSlideShow-article {
    border: none;
    overflow: visible;
  }

  .newsSlideShow-more,
  .newsSlideShow-next,
  .newsSlideShow-prev,
  .newsSlideShow-thumbnails {
    display: none;
  }
}

.coaches .aggHeader {
  background-color: $orange !important;
}

.orgs .aggHeader {
  background-color: $red !important;
}

.leaderboard {
  max-width: 720px;
  margin: 0 auto;
}

// Full Width CSS __________________________________
// Requirements
// + CSS Snippet (full-width)
// + Add class "full-width" to layout container

.full-width {
  box-sizing: border-box;
  padding: 0;
  width: 100vw;
  // width: calc(100vw - 30px);
  margin-left: calc(-1*(100vw - 100%)/2);
  padding: 30px calc((100vw - 100%)/2);
  background-color: white;
  // padding-left: 0;

  .edit_mode & {
    max-width: 100%;
    margin-left: 0;
  }
}

body.logged_in {
  .full-width {
    width: calc(100vw - 30px);
  }
}
// --------------------------------
//   VIDEOS
// --------------------------------

.video-container {
  background: linear-gradient(to bottom left, $blue, $green);
  #ngin21569 & {
    background: linear-gradient(to bottom left, $purple, $orange);
  }
  & .newsAggregatorElement .item {
    background-color: #ffffff;
    margin-bottom: 10px;
  } 
  margin-top: 60px;
  margin-bottom: 30px;
  @include mobile {
    padding: 30px 0;
  }

  .video-title {
    padding: 0;
  }

  .textBlockElement {
    margin: 0;
  }

  h3 {
    font-size: rem(36);
    line-height: rem(42);
    font-weight: 500;
    color: white;
    text-transform: none;
    margin-bottom: 0;
  }

  .video-header h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    // text-align: center !important;
  }

  .video {
    position: relative;

    &:before {
      // content: "\f04b";
      content: "\f0ed";
      font-family: fontAwesome;
      color: #fff;
      font-size: 50px;
      cursor: pointer;
      background-color: $yellow;
      position: absolute;
      height: 80px;
      width: 80px;
      line-height: 80px;
      text-align: center;
      // text-indent: 7px;
      top: 28px;
      left: 30px;
      z-index: 5;
      border-radius: 100%;
    }

    .sn-call-to-action {
      height: 260px !important;

      img {
        height: 260px !important;
        object-fit: cover;
      }

      .sn-call-to-action-overlay-text {
        text-align: left;
        vertical-align: top;
        padding-top: 75px;
      }

      .sn-call-to-action-title {
        font-size: rem(27);
        line-height: rem(34);
        text-transform: none;
      }

      .sn-call-to-action-overlay {
        background-color: rgba(0,0,0,.5);
        padding: 30px;
      }
    }
  }
}

// --------------------------------
//   TITLE ONLY NEWS AGG
// --------------------------------
.title-only .newsAggregatorElement {
  .commentsAndMore,
  .dateAuthor,
  .extendedOptions,
  .teaser,
  a > img {
    display: none;
  }

  .condensed {
    min-height: 20px;
  }

  .newsText {
    display: none;
  }

  .condensed:first-of-type a {
    width: 100%;
    float: none;

    & > img {
      display: block;
      width: 100%;
      max-width: 100%;
      float: none;
      margin-bottom: 20px;
      object-fit: cover;
    }
  }

  .condensed > a {
    padding: 0;
    display: inline;
  }

  .condensed .details {
    padding-left: 0;
    min-height: 48px;

    li {
      margin-bottom: 0;
    }
  }

  .aggHeader {
    margin: 15px 0;
    @include special-header;

    a {
      color: #fff;
    }
  }

  h4 a {
    color: $dark2;
    font-size: rem(18);
    font-weight: 300;
    line-height: rem(24);
  }

  .item {
    padding: 12px 0;
    border-top: 2px solid $light;

    &:nth-of-type(1) {
      border: none;
    }
  }
}

.user-group-container {
  background: linear-gradient(to bottom left, $yellow, $orange);

  h2,
  h3.aggHeader {
    font-size: rem(32);
    line-height: rem(38);
    font-weight: 400;
    color: white;
    padding: 0;
    margin: 0 0 5px;
    background-color: transparent;
    text-transform: none;
  }

  .summary a {
    color: #fff;
  }

  p {
    color: white;
  }

  .column {
    padding: 20px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }

  .pageEl {
    width: 100%;
    @include tablet-small {
      margin: 15px 0;
    }
  }
  .pageEl.forum-inline-code {
    margin-top: -30px;
  }

  .forum .newsAggregatorElement {
    .extendedOptions,
    .teaser,
    a > img {
      display: none;
    }

    .newsAuthor,
    .newsDate {
      color: white;
    }

    .item {
      padding: 12px 0;
      border-bottom: 2px solid white;

    }

    .condensed .details,
    a {
      padding: 0;
      display: inline;
    }

    h4 > a {
      color: white;
      font-size: rem(18);
      font-weight: 300;
      line-height: rem(24);
    }
  }

  .linkElement,
  h4 > a {
    color: #ffffff;
    text-transform: none;
    font-size: 20px;
    margin-left: 0;
  }

  .eventAggregatorElement {
    // .details { display: none; }
    .time {
      font-size: 0;
      color: transparent;
    }
    .time .dtstart .dtstart {
      font-size: 12px;
      color: #ffffff;
    }
    .time .dtstart .dtend {
      font-size: 12px;
      color: #ffffff;
      margin-left: 5px;
    }
    .goToLink {
      display: none;
    }
    .details {
      .description {
        display: none;
      }

      .tags {
        display: none;
      }

      .location a {
        color: #fff;
      }
    }
  }

  .linkElement h4 a:hover {
      text-decoration: underline;
  }

  .extendedOptions {
    text-align: left !important;
    color: #fff !important;

    .iCalLink,
    .rssLink {
      display: none;
    }
    // .goToLink::after {
    //   content: "";
    //   display: inline-block;
    //   position: absolute;
    //   height: 25px;
    //   width: 50px;
    //   background-color: #F0B428;
    // }
  }
}

.user-group-title .textBlockElement {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;

  h2 {
    font-size: rem(32);
    font-weight: 200;
    font-family: $fontOne;
    line-height: 50px;
  }
}

#search-modal {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 240px;
  z-index: 2000;
  transition: 0.3s ease;

  &.hidden {
    transform: translate3d(0, -100%, 0);

  }

  &.visible {
    transform: translate3d(0, 0, 0);

  }

  .search-container {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    height: 100%;
  }
}

progress {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  color: $blue;
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

progress::-webkit-progress-value {
  background-color: $blue;
}

progress::-moz-progress-bar {
  background-color: $blue;
}

// User Group square - Small

.user-group-small {
  padding: 20px;
  background: linear-gradient(to bottom left, #F0B428, #E16428);

  h3 {
    color: #ffffff;
    font-weight: 100;
    letter-spacing: 0.1em;
    text-indent: 60px;
    margin-bottom: 30px;

    &::before {
      content: url("../images/se-icon-white.svg");
      position: absolute;
      display: inline-block;
      height: auto;
      transform: translateX(-110%) translateY(-20%);
    }
  }

  .text {
    color: #ffffff;

    h4 {
      color: #ffffff;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 25px;
    }

    p {
      font-size: 0.7em;
      font-weight: 100;
      line-height: 1.8em;
    }

    a {
      font-size: 19px;
      color: #ffffff;
      font-weight: 100;
      background-color: $yellow;
      padding: 10px 30px;
      border-radius: 8px;
    }
  }
}

// SPORTSENGINE.COM FORM STYLES
.se-form {
  background: linear-gradient(to bottom left, $blue, $green);

  .pageElement {
    input[type="text"] {
      display: block;
      width: 100%;
      height: 44px;
      padding: 6px 10px;
      font-size: 16px;
      margin: 0 5px 5px 0;
      background-color: #fff;
      border: 1px solid $gray;
      border-radius: 5px;
      box-shadow: 0 2px 2px rgba(104,118,127,0.3);
      color: #23282b;
      font-weight: 400;

      &::placeholder {
        color: #c3cace;
        font-weight: 300;
      }
    }

    a.se-submit {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    a.se-submit,
    input[type=text] {
      @media only screen and (min-width: 767px) and (max-width:1100px) {
        flex: 1 1 100%;
        margin: 0 0 10px;
      }
    }

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (min-width: 767px) and (max-width:1100px) {
        flex-wrap: wrap;
      }
    }
  }
}

// Coaches page

.coach-lower {
  margin-bottom: 50px;
}

// #topNav.stickyNav{
//   position: fixed;
//   top: 0;
// }

body.logged_in {
  #topNav {
    position: absolute;
    top: 40px;

    &.stickyNav {
      position: fixed;
      top: 0;
    }
  }
}

// Hiding nav on scroll

#topNav.hideNav {
  transform: translateY(-140%);
}

// Video aggregator styling
body:not(.edit_mode) {
  .video-container .column {
    padding: 0;
    width: 32%;
  }

  .video-container .column:not(.column-3) {
    margin-right: 2%;
  }

  .video-title {
    margin-bottom: 20px;
  }

  .video-agg-element .newsAggregatorElement {
    margin: 0;

    .condensed > a img {
      max-width: none;
      min-height: 270px;
    }

    .condensed a {
      padding: 0;
      max-width: 100%;
    }

    .condensed {
      &::after {
        content: "";
        display: block;
        max-height: 270px;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 4;
        bottom: 11px;
      }

      .teaser {
        display: none;
      }
    }

    .extendedOptions {
      display: none;
    }

    .dateAuthor {
      display: none;
    }

    h4 {
      position: absolute;
      top: 60%;
      width: 80%;
      margin-left: 5%;
      font-size: 25px;
      z-index: 10;

      a {
        color: #fff;
      }

      &:before {
        content: "\f04b";
        font-family: fontAwesome;
        color: #fff;
        font-size: 50px;
        cursor: pointer;
        background-color: $yellow;
        position: absolute;
        height: 80px;
        width: 80px;
        line-height: 80px;
        text-align: center;
        text-indent: 7px;
        transform: translateY(-150%);
        // top: -140%;
        // left: 30px;
        z-index: 5;
        border-radius: 100%;
      }
    }
  }
}

.video-container {
  .column {
    min-height: 200px;
  }

  .layoutContainer.video-title .column {
    min-height: 40px;
  }
}

// related articles aggregator styling

.related-agg {
  .newsAggregatorElement {
    .condensed > a,
    img {
      display: none;
    }

    .aggHeader {
      text-transform: none;
      font-size: 36px;
      margin-bottom: 25px;
    }

    .extendedOptions {
      display: none;
    }

    .condensed {
      min-height: 100%;

      .details {
        padding-left: 0;

        .dateAuthor {
          display: none;
        }

        .teaser {
          display: none;
        }

        h4 {
          font-size: 20px;

          a {
            display: inline-block;
            color: $link-color;
          }
        }
      }
    }
  }
}

// new large featured news-aggregator
.featured.main {
  .newsAggregatorElement {
    height: 100%;

    .item {
      height: 96%;

      h4 a {
        font-size: 36px;
        line-height: 40px;
      }

      .teaser {
        display: block;
        font-size: 20px;
        line-height: 24px;
        padding-left: 15px;
      }

      .details {
        margin-top: 3%;
      }

      a {
        height: 75%;

        img {
          max-height: 550px;
        }
      }
    }
  }
}

.user-group-small.featured {
  margin-top: 15px;
  min-height: 375px;
}

// general mobile styling for homepage
html.has-mobile-nav {
  #panelTwo {
    padding: 0;
  }

  .newsSlideShow-headline h1 {
    padding: 10px;

    a {
      font-size: 24px;
    }
  }

  .featured {
    .newsAggregatorElement .condensed.item {
      min-height: inherit;
      padding: 0 0 5px;
      height: initial;
    }

    .aggHeader {
      bottom: 10px;
      left: 15px;
    }

    &-latest {
      .aggHeader {
        font-size: 24px;
        margin-top: 10px;
      }

      .item.condensed {
        min-height: inherit;

        a {
          width: 50%;
        }

        .details {
          padding-top: 0;

          h4 a {
            font-size: 14px;
            line-height: 1em;
          }
        }
      }
    }
  }

  .pageEl .adElement {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .full-width {
    width: 100%;
    margin-left: 0;

    &.video-container {
      width: 100%;
      margin: 0 auto;

      .column {
        width: 90%;
        margin: 0 auto;

        .video {
          &::before {
            height: 50px;
            width: 50px;
            font-size: 30px;
            line-height: 50px;
            top: 50px;
          }
        }
      }
    }
  }

  .user-group-container {
    .pageEl {
      margin: 0;
    }

    .user-group-title {
      padding: 0;
    }

    .title {
      margin: 0;

      .textBlockElement {
        margin: 0;

        h2 {
          font-size: 36px;
        }
      }
    }

    h2 {
      font-size: 26px;
    }

    p {
      font-size: 16px;
    }

    .aggregatorElement {
      .aggHeader {
        font-size: 26px;
      }

      .dateImage {
        padding: 0;
        float: left;
        margin-right: 10px;
        width: 75px;

        .month {
          margin-top: 10px;
          margin-bottom: 0;
        }

        .date {
          margin: 0;
          font-size: 32px;
        }
      }

      .summary {
        font-size: 15px;
      }

      .time {
        margin-bottom: 0;
      }
      // .location { margin-left: 30%; }
    }
  }

  .global-site-footer {
    .column-2 {
      display: none;
    }

    .se-description h3 {
      text-align: center;
    }
  }

  .snFooterContainer {
    width: 100%;
    padding: 0;
  }

  // pop up menu
  // .menu-overlay {
  //   background: transparent;
  //   .menu-container {
  //     padding-top: 30px;
  //     background-color: $background-color;
  //
  //     .large-menu .column-2 .pageEl {
  //       width: 45%;
  //       h3 {
  //         letter-spacing: 0;
  //         line-height: inherit;
  //       }
  //     }
  //   }
  //   .column-1 { display: none; }
  //   .close-icon {
  //     height: 20px;
  //     width: 20px;
  //     top: 25px;
  //     right: 15px;
  //     z-index: 10;
  //   }
  //   .column { padding: 0; }
  //   .heroPhotoElement { width: 90%; }
  // }
}

// tabs on equipment article

.newsContentNode .equipment {
  .pageEl .textBlockElement {
    max-width: 100%;
    border-bottom: 1px solid $gray;
    padding-bottom: 20px;

    p {
      margin-left: 25%;
    }

    h4 {
      margin-left: 25%;
      margin-bottom: 20px;
    }
  }
}

.search-icon, .menu-icon {
  display: none;
}
