
  .pageEl:not(.priceline) {
    .adElement {
      background-color: $light;
      padding-top: 15%;
      padding-bottom: 17%;
      position: relative;

      .ad-wrapper::before {
        content: "Advertisement";
        display: block;
        position: absolute;
        font-family: $fontTwo;
        color: $dark2;
        font-size: 14px;
        font-weight: 100;
        letter-spacing: 1px;
        top: 25px;
        left: 35px;
      }
    }
  }
