html:not(.has-mobile-nav) {
  #community-form {
    .fsEmbed .fsPage {
      width: 450px;
    }
  }
}

#community-form {
  .form-header {
    max-width: 750px;
    margin: 20px auto;
    float: none;
    h3 {
      font-family: $fontOne;
      font-size: 48px;
      font-weight: 100;
      color: $dark2;
    }
    p {
      margin-top: 20px;
      font-smoothing: always;
    }
  }

  form { padding: 0; }

  .fsForm { margin-top: 0; }

  .fsEmbed .fsPage {
    // width: 450px;
    margin: 0 auto;
  }
  label, legend {
    font-family: $fontTwo;
    font-size: 16px;
    font-weight: 100;
    color: $dark2;
  }
  .fsRowBody input[type=email]{
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  .fsRowBody input[type=checkbox] {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    &::before {
      width: 24px;
      height: 24px;
      border-radius: 5px;
    }
  }
  .fsRowBody input[type=checkbox]:checked:before {
    background-color: $orange;
    border: transparent;
  }

  label.fsOptionLabel {
    line-height: 40px;
  }

  .fsSubmit input.fsSubmitButton {
    background-color: $yellow !important;
    min-width: 150px;
    padding: 15px;
    border-radius: 5px !important;
    font-size: 24px !important;
  }

  .fieldset-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label.fsOptionLabel { width: 40%; }
    .fsSupporting {
      width: 100%;
      line-height: 22px;
    }
  }
}

// Specific mobile styles

html.has-mobile-nav {
  #community-form {
    .form-header {
      h3 {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .fieldset-content {
      flex-direction: column;
      label.fsOptionLabel { width: 100%; }
      @media (min-width: 768px){
        flex-direction: row;
        label.fsOptionLabel { width: 40%; }
      }
    }
  }
}
