body.facebook_app {
  #yieldContent {
    padding: 20px;
  }
  h2 {
    margin-bottom: 0px;
    &#displayBodyHeader {
      text-align: center;
      color: #23282B;
      font-family: $fontTwo;
      font-weight: 500;
      font-size: 32px;
    }
  }

  .newsAggregatorElement .extendedOptions {
    padding-bottom: 12px;
  }

  .newsItemElement .newsText { margin-top: 12px; }

  @media only screen and (max-width: 875px) {
    h2#displayBodyHeader {
      text-align: center;
      color: #23282B;
      font-size: 24px;
      margin-left: 10px;
      margin-bottom: 0px;
    }
    .newsItemElement .newsItemHeader {
      padding-left: 0px;
      a {
        position: relative;
        font-size: 20px;
      }
    }
    .newsItemElement .newsText {
      padding-left: 0px;
      margin-top: 0px;
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .newsAggregatorElement {
      .extendedOptions { padding-bottom: 20px; }
      h2 { margin-bottom: 5px; }
    }
    .pageElement.textBlockElement {
      margin-bottom: 0px;
    }
  }
}
